
import { defineComponent } from "vue";
import RoomTypeSection from "@/components/new-reservation/hotel-list/RoomTypeSection.vue";
import RoomPriceTable from "@/components/new-reservation/hotel-list/RoomPriceTable.vue";

export default defineComponent({
    name: "Hotel-Room-Type",
    components: {
        RoomTypeSection,
        RoomPriceTable,
    },
    props: {
        propHotelPrice: {},
        propPriceParams: {},
        propHotelCode: String,
    },
    setup(props) {

        function getRoomPriceCode(prmIndex) {
            return "RoomPrice_"+prmIndex;
        }

        return {
            getRoomPriceCode,
        };
    },
});
