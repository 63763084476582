
import {defineComponent, onMounted, onUpdated} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";

export default defineComponent({
    name: "Hotel Room Type Price Table",
    components: {
    },
    props: {
        propHotelPrice: {},
        propHotelCode: String,
        propRoomPrice: {},
        propPriceParams: {},
        propRoomPriceCode: String,
    },
    setup(props) {

      onUpdated(() => {
        changeCurSymbol();

      });
      onMounted(() => {
        changeCurSymbol();

      });

      function changeCurSymbol() {
        const priceParamsCur = document.getElementsByClassName("spanPriceParamsCur")
        for (let i=0; i<priceParamsCur.length; i++){
          let eachCur = priceParamsCur[i] as HTMLElement;
          if((props.propPriceParams as any).Cur==="TRY"){
            eachCur.innerText= "₺";
          }
          if((props.propPriceParams as any).Cur==="STG"){
            eachCur.innerText= "£";
          }
          if((props.propPriceParams as any).Cur==="EUR"){
            eachCur.innerText= "€";
          }

        }
      }


        function getAvailableLabel(prmItem) {
            if (prmItem.Availability==="AVAILABLE") {
                return "Müsait";
            }
            return "Müsait Değil";
        }

        function getAvailableClassName(prmItem) {
            if (prmItem.Availability==="AVAILABLE") {
                return "text-success";
            }
            return "text-danger";
        }


        function getCampaignStatus(prmItem) {

            //console.log("getCampaignStatus prmItem.CampaignStatus.isebexist: "+prmItem.CampaignStatus.isebexist);

            let rv = [];


            if (prmItem.CampaignStatus.IsDayProcessed) {
                //console.log("getCampaignStatus prmItem.CampaignStatus.isebexist: "+prmItem.CampaignStatus.isebexist);
                //console.log("getCampaignStatus prmItem.CampaignStatus.Campaigns: "+prmItem.CampaignStatus.Campaigns);
                //return prmItem.CampaignStatus.Campaigns;
                for (let i=0; i<prmItem.CampaignStatus.Campaigns.length; i++) {
                    if (prmItem.CampaignStatus.Campaigns[i].Type=="DAY") {
                        rv.push(prmItem.CampaignStatus.Campaigns[i]);
                    }
                }
            } else {
                for (let i=0; i<prmItem.CampaignStatus.Campaigns.length; i++) {
                    if (prmItem.CampaignStatus.Campaigns[i].Type=="EB" || prmItem.CampaignStatus.Campaigns[i].Type=="COMMISSION" ) {
                        rv.push(prmItem.CampaignStatus.Campaigns[i]);
                    }
                }
            }




            return rv;
        }

        function getSortedByDate(prmArray) {
            return prmArray.sort((a, b) => {
                if (a.Date < b.Date) {
                    return -1;
                }
            });
        }



        return {
            getAvailableLabel, getAvailableClassName, getCampaignStatus, getSortedByDate, libTools, dateConvert,
        };
    },
});
