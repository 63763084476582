import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-2 border-top d-flex" }
const _hoisted_2 = { class: "p-0 min-w-200px me-auto" }
const _hoisted_3 = { class: "text-dark fw-bolder text-hover-primary mb-1 fs-6" }
const _hoisted_4 = { class: "text-muted fw-bold d-block fs-7" }
const _hoisted_5 = { class: "p-0 min-w-100px text-end" }
const _hoisted_6 = { class: "fs-5 text-black fw-bolder" }
const _hoisted_7 = { class: "spanPriceParamsCur" }
const _hoisted_8 = { class: "text-success" }
const _hoisted_9 = { class: "p-0 min-w-40px border-0 d-flex align-items-start justify-content-end" }
const _hoisted_10 = { class: "accordion-item border-0" }
const _hoisted_11 = { class: "form-check form-check-custom form-check-solid" }
const _hoisted_12 = ["data-bs-target", "aria-controls"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.propRoomPrice.RoomTitle), 1),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.propRoomPrice.ConceptTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.libTools.method.localeStA(_ctx.propRoomPrice.AccountPrice, 2)) + " ", 1),
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.propPriceParams.Cur), 1)
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getAvailableLabel(_ctx.propRoomPrice)), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("input", {
            class: "form-check-input",
            type: "radio",
            value: "",
            name: "HotelPriceCode",
            "data-bs-toggle": "collapse",
            "data-bs-target": `#panelsStayOpen-collapsePriceTable_${_ctx.propHotelCode}_${_ctx.propRoomPriceCode}`,
            "aria-expanded": "false",
            "aria-controls": `panelsStayOpen-collapsePriceTable_${_ctx.propHotelCode}_${_ctx.propRoomPriceCode}`,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click_HotelPrice && _ctx.click_HotelPrice(...args)))
          }, null, 8, _hoisted_12)
        ])
      ])
    ])
  ]))
}