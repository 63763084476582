
import {defineComponent, onMounted, onUpdated, ref} from "vue";
import { Field,} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import PosGarantiFormModal from "@/components/new-reservation/payment/PosGarantiFormModal.vue";
import PosNomuPayFormModal from "@/components/new-reservation/payment/PosNomuPayFormModal.vue";
import AppCore from "@/core/appcore/AppCore";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

interface ICardInfo {
    CardHolderName: string;
    CardNumber: string;
    CardExpiryMonth: string;
    CardExpiryYear: string;
    CardCvv: string;
}

interface IBankPosInfo {
    BankPosID: number;
    InstallmentCount: number;
}

export default defineComponent({
    name: "Payment Info",
    components: {
        Field,
        PosGarantiFormModal,
        PosNomuPayFormModal,
    },



    setup(props, {emit}) {

      onMounted(()=> {
        changeCurSymbol();
      });

      onUpdated(() => {
        changeCurSymbol();
      });

        var theCardInfo = ref<ICardInfo>({
            CardHolderName: "",
            CardNumber: "",
            CardExpiryMonth: "",
            CardExpiryYear: "",
            CardCvv: "",
        });

        var theBankPosInfo = ref<IBankPosInfo>({
            BankPosID: 0,
            InstallmentCount: 0,
        });

        let refComp_ModalPosGarantiForm = ref(null);
        let refComp_ModalPosNomuPayForm = ref(null);

        let arrBankPoses = ref([]);
        let arrBankPosInstallments = ref([]);
        let selBankPos = ref({});


      function changeCurSymbol() {
        const myPriceCur = AppCore.prsCore_Flight.ccvPriceCur
        const priceParamsCur = document.getElementsByClassName("spanPriceParamsCur")
        for (let i=0; i<priceParamsCur.length; i++){
          let eachCur = priceParamsCur[i] as HTMLElement;
          if(myPriceCur==="TRY"){
            eachCur.innerText= "₺";
          }
          if(myPriceCur==="STG"){
            eachCur.innerText= "£";
          }
          if(myPriceCur==="EUR"){
            eachCur.innerText= "£";
          }
        }
      }

        function initComp() {
            console.log("PayInfo.vue initComp START");
            setCardInfoVisuals_By_BankPos();
            arrBankPoses.value = [];
            store.dispatch(Actions.BANKPOS_LIST, {}).then((prmData) => {
                if (prmData.data!==undefined) {
                    console.log("BANKPOS_LIST: "+JSON.stringify(prmData));
                    arrBankPoses.value = prmData.data;
                }
            });
        }

        function resetComp() {
            console.log("PayInfo.vue resetComp START");
            theBankPosInfo.value. BankPosID = 0;
            console.log("AllTotal " + AppCore.prsCore_Flight.theStepsBasket.AllTotal);
        }

        function getObjIns(prmIns) {
            if (prmIns!==undefined) {
                return JSON.parse(prmIns);
            }
            return [];
        }

        function change_Sel_BankPos(){

            arrBankPosInstallments.value = [];

            if (theBankPosInfo.value.BankPosID>0) {
                selBankPos.value = getBankPos_By_BankPosID(theBankPosInfo.value.BankPosID);
                setCardInfoVisuals_By_BankPos();

                store.dispatch(Actions.BANKPOS_INSTALLMENT_WITH_COMMISSION_LIST, {BankPosID: theBankPosInfo.value.BankPosID, Type: AppCore.prsCore_Flight.ccvSearchType, AllTotal:AppCore.prsCore_Flight.theStepsBasket.AllTotal}).then((prmData) => {
                    if (prmData.data!==undefined) {
                        arrBankPosInstallments.value = prmData.data;
                    }
                });
            }
        }

        function setCardInfoVisuals_By_BankPos() {
            (document.getElementById("divPayInfo_CardInfo") as HTMLElement).style.display = "none";
            (document.getElementById("btnPayInfo_Payment_Garanti") as HTMLElement).style.display = "none";
            (document.getElementById("btnPayInfo_Payment_NomuPay") as HTMLElement).style.display = "none";


            if (selBankPos.value!==undefined && selBankPos.value!==null && (selBankPos.value as any).ID!==undefined) {
                //### Garanti
                if ((selBankPos.value as any).BankID+"" === "1") {
                    (document.getElementById("divPayInfo_CardInfo") as HTMLElement).style.display = "";
                    (document.getElementById("btnPayInfo_Payment_Garanti") as HTMLElement).style.display = "";
                }

                //### NomuPay
                if ((selBankPos.value as any).BankID+"" === "7") {
                    (document.getElementById("btnPayInfo_Payment_NomuPay") as HTMLElement).style.display = "";
                }
            }
        }

        function getBankPos_By_BankPosID(prmBankPosID){
            //console.log("getBankPos_By_BankPosID START prmBankPosID: "+prmBankPosID);
            let rv = null;
            if (arrBankPoses.value!==undefined && arrBankPoses.value!==null) {
                //console.log("getBankPos_By_BankPosID v1");
                for (let i=0; i<arrBankPoses.value.length; i++) {
                    let each = arrBankPoses.value[i];
                    //console.log("getBankPos_By_BankPosID each "+JSON.stringify(each));
                    if (each.ID === prmBankPosID) {
                        return each;
                    }
                }
            }
            return rv;
        }

        function getCommisionIns(prmCommission, prmInsCount) {
          let rv = (AppCore.prsCore_Flight.theStepsBasket.AllTotal + (AppCore.prsCore_Flight.theStepsBasket.AllTotal / 100) * prmCommission) / prmInsCount;
          rv = Math.ceil(rv*100)/100;
          return rv;
        }

        function click_ShowModalPosGaranti() {
            //AppCore.prsCore_Flight.theStepsBasket.AllTotal

            //console.log("click_ShowModalPosGaranti selBankPos: "+JSON.stringify(selBankPos.value));
            //let vvAmount = 1 * 100;
            let vvAmount = Number(AppCore.prsCore_Flight.theStepsBasket.AllTotal) * 100;
            if (theBankPosInfo.value.InstallmentCount+"" === "1") {
                theBankPosInfo.value.InstallmentCount = 0;
            }
            (refComp_ModalPosGarantiForm.value as any).showComp(theCardInfo.value, theBankPosInfo.value, vvAmount, "TRY", "");
        }

        function click_ShowModalPosNomuPay() {
            //let vvAmount = 1 * 100;
            let vvAmount = Number(AppCore.prsCore_Flight.theStepsBasket.AllTotal) * 100;
            if (theBankPosInfo.value.InstallmentCount+"" === "1") {
                theBankPosInfo.value.InstallmentCount = 0;
            }
            (refComp_ModalPosNomuPayForm.value as any).showComp(theCardInfo.value, theBankPosInfo.value, vvAmount, "TRY", "");
        }

        function posProcessEnded(prmData) {
            console.log("PayInfo posProcessEnded "+JSON.stringify(prmData));
            emit("posProcessEnded", prmData);
        }

        function click_BankPosInsItem(event) {
            console.log("click_BankPosInsItem id: "+JSON.stringify(event.currentTarget.id)+" value: "+event.currentTarget.value);
            theBankPosInfo.value.InstallmentCount = event.currentTarget.value;
            const colBankInsItems = document.getElementsByClassName("payinfo-bankinsitem");
            for (let i = 0; i < colBankInsItems.length; i++) {
                const vvElement = colBankInsItems[i] as HTMLInputElement;
                const vvId = vvElement.getAttribute("id");
                if (event.currentTarget.id!==vvId) {
                    vvElement.checked  = false;
                }
            }

            store.dispatch(Actions.FLIGHT_RESERVATION_ADD_INSTALLMENT, {SearchCode: AppCore.prsCore_Flight.ccvSearchCode, InstallmentCount:event.currentTarget.value, AllTotal: AppCore.prsCore_Flight.theStepsBasket.AllTotal, Commission:event.currentTarget.getAttribute("commission")}).then((prmData) => {
                if (prmData.data!==undefined) {
                    //arrBankPosInstallments.value = prmData.data;
                }
            });
        }

        return {
            AppCore,
            libTools,
            initComp, resetComp,
            refComp_ModalPosGarantiForm, refComp_ModalPosNomuPayForm,
            theCardInfo, theBankPosInfo,
            click_ShowModalPosGaranti, click_ShowModalPosNomuPay,
            change_Sel_BankPos,
            posProcessEnded,
            arrBankPoses,
            arrBankPosInstallments,
            getObjIns,
            click_BankPosInsItem,
            getCommisionIns,

        };

    },


});
