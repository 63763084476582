
import {defineComponent, onMounted, onUpdated, ref} from "vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";


export default defineComponent({
    name: "Hotel-Content",
    components: {
    },
    props: {
        propHotelPrice: {},
        propPriceParams: {},
        propHotelCode: String,
    },
    setup(props) {

      onUpdated(() => {
          changeCurSymbol();

        });
      onMounted(() => {
        changeCurSymbol();

      });

      function changeCurSymbol() {
        const priceParamsCur = document.getElementsByClassName("spanPriceParamsCur")
        for (let i=0; i<priceParamsCur.length; i++){
          let eachCur = priceParamsCur[i] as HTMLElement;
          if((props.propPriceParams as any).Cur==="TRY"){
            eachCur.innerText= "₺";
          }
          if((props.propPriceParams as any).Cur==="STG"){
            eachCur.innerText= "£";
          }
          if((props.propPriceParams as any).Cur==="EUR"){
            eachCur.innerText= "€";
          }

        }
      }





        return {
            libTools,
        };
    }

});
